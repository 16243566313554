<template>
	<div>
		<el-card class="filter-container" shadow="never">
			<el-form ref="form" :model="form" :rules="dataRule" label-width="80px">
				<el-form-item label="上级科室">
					<el-select v-model="form.parentNo" placeholder="上级科室" style="width: 30%;">
						<el-option v-for="item in list" :key="item.deptNo" :label="item.deptName" :value="item.deptNo"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="科室名称"  prop="deptName">
					<el-input v-model="form.deptName" style="width: 30%;"></el-input>
				</el-form-item>

				<el-form-item label="科室位置">
					<el-input v-model="form.location" style="width: 30%;"></el-input>
				</el-form-item>

				<!-- 	<el-form-item label="是否特色">
					<el-switch v-model="form.delivery"></el-switch>
				</el-form-item> -->

				<el-form-item label="是否开启">
					<el-switch v-model="form.isShow"></el-switch>
				</el-form-item>

				<el-form-item label="排序">
                    <el-input v-model="form.sort" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
				</el-form-item>

				<el-form-item label="图片">
					<el-upload class="upload-demo" :action="uploadimgurl" style="width: 30%;" :on-success="onsuccess" 
					 :limit="1" :on-exceed="onexceed" :file-list="form.filelist"  :on-remove="onremove"  list-type="picture">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">更新照片前，须先删除原照片</div>
					</el-upload>
				</el-form-item>

				<el-form-item label="科室描述">
					<!-- <el-input v-model="form.desc"></el-input> -->
					<div id="div1"></div>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info" @click="gobreak">取消</el-button>
				</el-form-item>
			</el-form>
		</el-card>

	</div>


</template>
<script>
	import E from "wangeditor";
	export default {
		data() {
			return {

				imageUrl: '',
				form: {
					deptPic: "",
					deptName: "",
					parentNo: "",
					deptDesc: "",
					sort: "",
					isShow: false,
					location: "",
					filelist: [],
					// deptNo
					// deptName
					// parentNo
					// deptDesc
					// sort
					// isShow
					// location
					// deptPic
				},
				query: [],
				
				newsImg: "",
				uploadimgurl: '', // 图片上传路径
				list: [],
        dataRule: {
            deptName: [
                { required: true, message: '科室名称不能为空', trigger: 'blur' }
            ]
        },
			}
		},
		methods: {
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},
			onSubmit() {
				const that = this;
				let apiUrl = "";
        that.$refs['form'].validate((valid) => {
            if (valid) {
                this.form.parentNo = this.form.parentNo === "" ? 0 : this.form.parentNo;
                this.form.isShow = this.form.isShow === true ? 1 : 0;
                if (this.newsImg !== "") {
                    this.form.deptPic = this.newsImg;
                }
                if (this.query.ac == "edit") {
                    //修改逻辑
                    apiUrl = '/admin/department/edit';
                } else {
                    //添加逻辑
                    // delete this.form.deptNo;
                    apiUrl = '/admin/department/add';

                }
                this.api(apiUrl, this.form).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            showClose:true,
                            type:"success",
                            message:"操作成功",
                            duration:800,
                            onClose(){
                                that.$router.replace({name:"departmentlist"});
                            }
                        })
                    }
                });
            }
        })
			},
			// 点击删除
			onremove(file, fileList) {
				const that = this;
				that.newsImg='';
			},
			// 图片上传成功
			onsuccess(file) {
				const that = this;
				that.newsImg = file.data.filePath;
			},
			// 获取列表
			getlist() {
				const that = this;
        that.api('/api/department/list',{isShow: '1', parentNo: '0'}).then(res => {
          that.list = res.data;
				})
			},
			// 图片上传超出
			onexceed(e) {
				const that = this;
				this.$message({
					showClose: true,
					message: '数量超出限制',
					type: 'warning'
				});

			},
			//获取修改科室信息
			getDept() {
				const that = this;
				that.api('/admin/department/detail', {
					deptNo: that.query.id
				}).then(res => {

					if(res.code==200){
						that.form.deptNo = res.data.deptNo;
						that.form.deptName = res.data.deptName;
						that.form.parentNo = res.data.parentNo == 0 ? "" : res.data.parentNo;
						that.form.deptDesc = res.data.deptDesc;
						that.form.sort = res.data.sort;
						that.form.location = res.data.location;
						that.form.isShow = res.data.isShow == 1 ? true : false;
						that.newsImg=res.data.deptPic;
            console.log(res.data)
						// that.form.filelist.push({
						// 	name: "图片",
						// 	url: that.$utils.apiurl + res.data.deptPic
						// });
						
						if(res.data.deptPic!=''){
							var imgshow={
								url:that.$utils.apiurl+res.data.deptPic
							}
							
							that.form.filelist.push(imgshow)
						}
						
						that.editor.txt.html(that.form.deptDesc);
					}
				})
			}
		},

		mounted() {
			const that = this;
			that.editor = new E("#div1");
			that.editor.config.onchange = function(newHtml) {
				that.form.deptDesc = newHtml;
			};
			that.editor.config.uploadImgMaxLength = 1;
			// that.editor.config.uploadImgServer = "http://bzjj.hrbcft.com/api/index/up1";
			// that.editor.config.uploadFileName = "upfile";
			// 接口地址 key
			that.editor.config.uploadImgServer = that.$utils.uploadimgurl;
			that.editor.config.uploadFileName = "file";

			that.editor.config.uploadImgHooks = {
				// 图片上传并返回了结果，想要自己把图片插入到编辑器中
				// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
				customInsert: function(insertImgFn, result) {
					// result 即服务端返回的接口
					var img = that.$utils.apiurl + result.data.filePath
					// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
					insertImgFn(img)
				}
			}
			that.editor.create();
		},

		beforeDestroy() {
			const that=this;
			// 销毁编辑器
			that.editor.destroy()
			that.editor = null
		},

		created() {
			const that = this;
			that.query = that.$route.query;
			that.uploadimgurl = that.$utils.uploadimgurl;
			that.getlist();
			if (that.query.ac == "sub") {
				that.form.parentNo = that.query.id;
			} else if (that.query.ac == "edit") {
				that.getDept();
				// let deptInfo = this.$utils.getOne(that.query.list,that.query.id,"deptNo");
				// console.log(deptInfo);
				// that.form.dept_no = deptInfo.deptNo;
				// that.form.deptName = deptInfo.deptName;
				// that.form.parentNo = deptInfo.parentNo==0?"":deptInfo.parentNo;
				// that.form.deptDesc = deptInfo.deptDesc;
				// that.form.sort = deptInfo.sort;
				// that.form.location = deptInfo.location;
				// that.form.isShow = deptInfo.isShow==1?true:false;
			}
		}
	}
</script>

<style scoped lang="less">
</style>
